import React, {useState, useEffect} from 'react';
import {
    useNavigate
} from "react-router-dom";
import {IP} from "./config.js";
import MatchTextField from "./components/match_text_field";
import {faEnvelope, faLock} from "@fortawesome/free-solid-svg-icons";


const Login = (props) => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    let navigate = useNavigate();

    function login() {
        var form = new FormData();
        form.append("email", email);
        form.append("password", password);

        fetch(IP + '/api/login', {
            method: 'POST', body: form, mode: "cors", credentials: "include",
        }).then((resp) => {
            resp.json().then((json) => {
                if (!('error' in json)) {
                    window.location.reload(false);
                }
            });
        });
    }


    const handleTitleClick = () => {
        navigate('/register');
    }


    useEffect(() => {

    })

    return (<>
        <div className="flex w-full justify-center place-items-center">
            <div className="card w-96 bg-base-100 shadow-xl my-10">
                <figure className="px-10 pt-10 mb-4">
                    <img className="mask mask-circle w-24" src={IP + "/static/aelkimi_logo.png"} alt="MATCH Logo"
                         width='100'/>
                </figure>
                <div className="card-body items-center text-center">
                    <h2 className="card-title">Welcome back.</h2>

                    <MatchTextField
                        prefixIcon={faEnvelope}
                        label={'Email'} onChange={e => setEmail(e.target.value)}
                        placeHolder={'info@site.com'}></MatchTextField>
                    <MatchTextField prefixIcon={faLock} label={'Password'} onChange={e => setPassword(e.target.value)}
                                    inputType={'password'} showPassword={true}
                                    placeHolder={'··············'}></MatchTextField>
                    <div className="card-actions">
                        <button onClick={() => login()} className="btn btn-primary mt-4">Login</button>
                    </div>
                    <div className='flex space-x-4'>
                        <h5>Don't have a account?</h5>
                        <h3 onClick={handleTitleClick} className='underline text-blue-600 cursor-pointer'>Create
                            New</h3>
                        {/*<a href="https:// " className='underline text-blue-600'>Create New</a>*/}
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default Login;
