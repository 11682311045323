import React, { useState, useEffect, useCallback } from 'react';
import Menu from './Menu.js';
import { IP } from "./config.js";
import debounce from 'lodash.debounce'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";


const localizer = momentLocalizer(moment)


const CalendarView = (props) => {
  const [requests, setRequests] = useState([]);
  const [startups, setStartups] = useState([]);
  const state = {
    events: [
      {
        start: moment().toDate(),
        end: moment()
          .add(1, "days")
          .toDate(),
        title: "Some title"
      }
    ]
  };

  function getRequests() {
    // var form = new FormData();
    // form.append("name", name);
    // form.append("project_str", "");

    fetch(IP + '/api/requests/get', {
      method: 'POST',
      // body: form,
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      resp.json().then((json) => {
        console.log(json);
        setRequests(json);
      });
    });
  }

  function getAllStartups() {
    // var form = new FormData();
    // form.append("name", name);
    // form.append("project_str", "");

    fetch(IP + '/api/startups/getall', {
      method: 'POST',
      // body: form,
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      resp.json().then((json) => {
        console.log(json);
        setStartups(json);
      });
    });
  }

  useEffect(() => {
    getRequests();
    getAllStartups();
  }, [])

  return (
      <>
<div className="flex flex-row preview border-base-300 bg-base-200 rounded-b-box rounded-tr-box flex min-h-[6rem] min-w-[18rem] flex-wrap items-left justify-top gap-2 overflow-x-hidden border bg-cover bg-top p-4 undefined w-full h-full" style={{backgroundSize: "5px 5px", backgroundImage: "radial-gradient(hsla(var(--bc)/.2) .5px,hsla(var(--b2)/1) .5px)", zIndex: 0}}>
      <Menu />
      <div className="menu bg-base-100 flex-1 p-2 rounded-box">

    <Calendar
          localizer={localizer}
          defaultDate={new Date()}
          defaultView="month"
          events={state.events}
          style={{ height: "100vh" }}
    />

      </div>
</div>
      </>
  )
}
export default CalendarView;
