import React, {useState, useEffect} from "react";
import {useForm} from "react-hook-form";


import {
    json,
    useNavigate, useRevalidator
} from "react-router-dom";

import {IP} from "./config";
import MatchTextField from "./components/match_text_field";
import {faEnvelope, faLock, faUser} from "@fortawesome/free-solid-svg-icons";
import {Form} from "react-router-dom";

const Register = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fullName, setFullName] = useState("");

    let navigate = useNavigate();

    const onSubmit = (data) => {
        setEmail(data.email);
        setFullName(data.fullName);
        setPassword(data.password);
        console.log(data);
    }


    // register request
    function onRegister() {
        let form = new FormData();
        form.append("email", email);
        form.append("password", password);
        form.append("name", fullName);
        console.log(email, password, fullName);


        fetch(IP + '/api/register', {
            method: 'POST',
            body: form,
            mode: "cors",
            credentials: "include",
        }).then((resp) => {
            resp.json().then((json) => {
                if (!('error' in json)) {
                    window.location.reload(false);
                }
            })
        })
    }


    useEffect(() => {

    })


    return (
        <>
                <div className="flex w-full justify-center ">
                    <div className="card w-96 bg-base-100 shadow-xl my-10">
                        <figure className="px-10 pt-10 mb-4">
                            <img className="mask mask-circle w-24" src={IP + "/static/match_logo.png"} alt="MATCH Logo"
                                 width='100'/>
                        </figure>
                        <div className="card-body Items-center text-center">
                            <h2 className="card-title justify-center">Register Now</h2>
                            <MatchTextField

                                prefixIcon={faUser} label={'Full Name'}
                                            onChange={e => setFullName(e.target.value)}
                                            placeHolder={'Full name'}

                            ></MatchTextField>
                            <MatchTextField
                                prefixIcon={faEnvelope} label={'Email'}
                                            onChange={e => setEmail(e.target.value)} inputType={'text'}
                                            placeHolder={'info@site.com'}


                            ></MatchTextField>

                            <MatchTextField
                                prefixIcon={faLock} label={'Password'}
                                            onChange={e => setPassword(e.target.value)}
                                            inputType={'password'} placeHolder={'*****'}
                                            showPassword={true}></MatchTextField>

                            <div className="flex flex-col card-actions = place-items-center">
                                <div className="space-y-10"></div>
                                <button
                                    onClick={() => onRegister()}
                                        className="btn btn-primary  mt-4">Register
                                </button>
                                <h2 className="text-lg pt-1 underline text-blue-600 cursor-pointer" onClick={() => {
                                    navigate('/login')
                                }}>Back to login</h2>

                            </div>
                        </div>
                    </div>

                </div>
            </>
    )
}


export default Register;

