import React, {useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope, faEye, faEyeSlash, faPerson} from "@fortawesome/free-solid-svg-icons";


const MatchTextField = ({
                            refs,
                            prefixIcon,
                            label,
                            value,
                            onChange,
                            inputType = 'text',
                            placeHolder,
                            showPassword = false
                        }) => {
    let [seePass, seePassword] = useState(false);

    return (
        <div className="form-control ">
            <label className="label">
                <span className="label-text">{label}</span>
            </label>
            <label className="input-group rounded-lg">

                {/*<span>&middot;</span>*/}
                <span>
                          {/*<i className="fa-fa-search"></i>*/}
                    <FontAwesomeIcon icon={prefixIcon}></FontAwesomeIcon>

                      </span>
                <input ref={refs} onChange={onChange} type={seePass ? 'text' : inputType} placeholder={placeHolder}
                       className="input input-bordered"/>
                {
                    showPassword ?
                        <div className=" absolute inset-y- right-0  flex items-center px-11 py-4 cursor-pointer">
                            <FontAwesomeIcon onClick={() => seePassword(!seePass)}
                                             icon={seePass ? faEye : faEyeSlash}></FontAwesomeIcon>
                        </div>
                        : null
                }
            </label>
        </div>
    )
}


export default MatchTextField;