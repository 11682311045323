import React, { useState, useEffect, useCallback } from 'react';
import Menu from './Menu.js';
import { IP } from "./config.js";
import debounce from 'lodash.debounce'


const Dashboard = (props) => {
  const [requests, setRequests] = useState([]);
  const [startups, setStartups] = useState([]);

  function getRequests() {
    // var form = new FormData();
    // form.append("name", name);
    // form.append("project_str", "");

    fetch(IP + '/api/requests/get', {
      method: 'POST',
      // body: form,
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      resp.json().then((json) => {
        console.log(json);
        setRequests(json);
      });
    });
  }

  function getAllStartups() {
    // var form = new FormData();
    // form.append("name", name);
    // form.append("project_str", "");

    fetch(IP + '/api/startups/getall', {
      method: 'POST',
      // body: form,
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      resp.json().then((json) => {
        console.log(json);
        setStartups(json);
      });
    });
  }

  useEffect(() => {
    getRequests();
    getAllStartups();
  }, [])

  return (
      <>
<div className="flex flex-row preview border-base-300 bg-base-200 rounded-b-box rounded-tr-box flex min-h-[6rem] min-w-[18rem] flex-wrap items-left justify-top gap-2 overflow-x-hidden border bg-cover bg-top p-4 undefined w-full h-full" style={{backgroundSize: "5px 5px", backgroundImage: "radial-gradient(hsla(var(--bc)/.2) .5px,hsla(var(--b2)/1) .5px)", zIndex: 0}}>
      <Menu />
      <div className="menu bg-base-100 flex-1 p-2 rounded-box">
        <div class="stats shadow">
          <div class="stat">
            <div class="stat-figure text-primary">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-8 h-8 stroke-current"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path></svg>
            </div>
            <div class="stat-title">Your Requests</div>
            <div class="stat-value text-primary">{ requests.length }</div>
            <div class="stat-desc">Request you sent so far</div>
          </div>
          
          <div class="stat">
            <div class="stat-figure text-secondary">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-8 h-8 stroke-current"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path></svg>
            </div>
            <div class="stat-title">Employees</div>
            <div class="stat-value text-secondary">{ startups.length }</div>
            <div class="stat-desc">Employees in our DB as of today</div>
          </div>
          
          <div class="stat">
            <div class="stat-figure text-secondary">
              <div class="avatar">
                <div class="w-16 rounded-full">
                  <img src={IP + '/static/match_logo.png'} />
                </div>
              </div>
            </div>
            <div class="stat-value">86%</div>
            <div class="stat-title">Match rate</div>
            <div class="stat-desc text-secondary">as of today</div>
          </div>
        </div>
        <div className="flex items-center justify-center flex-1">
          <button onClick={() => window.location.href='/directory'} className="btn btn-primary">FIND EMPLOYEES</button>
        </div>
      </div>
</div>
      </>
  )
}
export default Dashboard;
