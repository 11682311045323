import React from 'react';
import { IP } from './config.js';

const Menu = (props) => {
  function logout() {
    fetch(IP + '/api/logout', {
      method: 'POST',
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      resp.json().then((json) => {
        console.log(json);
        window.location.reload(false);
      });
    });
  }

  return (
      <>
        <ul className="menu bg-base-100 w-56 p-2 rounded-box">
          <li className="menu-title"><span>Home</span></li>
          <li><a href="/dashboard">Dashboard</a></li>
          <li><a href="/profile">Profile</a></li>
          <li><a onClick={() => logout()}>Logout</a></li>

          <li className="menu-title"><span>Core HR</span></li>
          <li><a href="/onboarding">Onboarding</a></li>
          <li><a href="/offboarding">Offboarding</a></li>
          <li><a href="/employees">Employee Management</a></li>
          <li><a href="/policies">Company policies</a></li>
          <li><a href="/calendar">Calendar</a></li>

          <li className="menu-title"><span>Workforce Management</span></li>
          <li><a href="/attendance">Time & Attendance</a></li>
          <li><a href="/biometrics">Biometrics</a></li>
          <li><a href="/leave">Leave</a></li>

          <li className="menu-title"><span>Cost Management</span></li>
          <li><a href="/payroll">Payroll</a></li>
          <li><a href="/payslips">Payslips</a></li>
          <li><a href="/finance">Finance</a></li>
          <li><a href="/expense">Expense</a></li>
          <li><a href="/reports">Reports</a></li>

          <li className="menu-title"><span>Performance Management</span></li>
          <li><a href="/training">Training</a></li>
          <li><a href="/feedback">360 Feedback</a></li>
          <li><a href="/psychometrics">Psychometrics</a></li>
          <li><a href="/health">Health & Wellness</a></li>

          <li className="menu-title"><span>Global Mobility</span></li>
          <li><a href="/reports">Compliance</a></li>
          <li><a href="/reports">EoR</a></li>
        </ul>
      </>
  )
}

export default Menu;
