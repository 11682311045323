import React, { useState, useEffect, useCallback } from 'react';
import Menu from './Menu.js';
import { IP } from "./config.js";
import debounce from 'lodash.debounce'


const Employees = (props) => {
  const [requests, setRequests] = useState([]);
  const [startups, setStartups] = useState([]);

  function getRequests() {
    // var form = new FormData();
    // form.append("name", name);
    // form.append("project_str", "");

    fetch(IP + '/api/requests/get', {
      method: 'POST',
      // body: form,
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      resp.json().then((json) => {
        console.log(json);
        setRequests(json);
      });
    });
  }

  function getAllStartups() {
    // var form = new FormData();
    // form.append("name", name);
    // form.append("project_str", "");

    fetch(IP + '/api/startups/getall', {
      method: 'POST',
      // body: form,
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      resp.json().then((json) => {
        console.log(json);
        setStartups(json);
      });
    });
  }

  useEffect(() => {
    getRequests();
    getAllStartups();
  }, [])

  return (
      <>
<div className="flex flex-row preview border-base-300 bg-base-200 rounded-b-box rounded-tr-box flex min-h-[6rem] min-w-[18rem] flex-wrap items-left justify-top gap-2 overflow-x-hidden border bg-cover bg-top p-4 undefined w-full h-full" style={{backgroundSize: "5px 5px", backgroundImage: "radial-gradient(hsla(var(--bc)/.2) .5px,hsla(var(--b2)/1) .5px)", zIndex: 0}}>
      <Menu />
      <div className="menu bg-base-100 flex-1 p-2 rounded-box">

        <div className="tabs">
          <a className="tab tab-lifted">Workflows</a>
          <a className="tab tab-lifted tab-active">Employees</a>
          <a className="tab tab-lifted">Job Offering</a>
          <a className="tab tab-lifted">Analytics</a>
        </div>

        <div className="overflow-x-auto">
          <table className="table table-compact w-full">
            <thead>
              <tr>
                <th></th> 
                <th>Full Name</th> 
                <th>Position</th> 
                <th>E-Mail</th> 
                <th>Hiring Lead</th> 
                <th>Workflow</th> 
                <th>Actions</th>
              </tr>
            </thead> 
            <tbody>
              <tr>
                <th>1</th> 
                <td>Cy Ganderton</td> 
                <td>Quality Control Specialist</td> 
                <td>cy@rhome.world</td> 
                <td>Sammy</td> 
                <td>
                    <ul className="steps">
                      <li className="step step-primary">Pre-Onboarding</li>
                      <li className="step step-primary">Onboarding</li>
                      <li className="step">Complete</li>
                    </ul>
                </td> 
                <td>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                </td>
              </tr>
              <tr>
                <th>2</th> 
                <td>Hart Hagerty</td> 
                <td>Desktop Support Technician</td> 
                <td>hart@rhome.world</td> 
                <td>Daniel</td> 
                <td>
                    <ul className="steps">
                      <li className="step step-primary">Pre-Onboarding</li>
                      <li className="step">Onboarding</li>
                      <li className="step">Complete</li>
                    </ul>
                </td> 
                <td>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                </td>
              </tr>
              <tr>
                <th>3</th> 
                <td>Brice Swyre</td> 
                <td>Tax Accountant</td> 
                <td>brice@rhome.world</td> 
                <td>Sammy</td> 
                <td>
                    <ul className="steps">
                      <li className="step step-primary">Pre-Onboarding</li>
                      <li className="step step-primary">Onboarding</li>
                      <li className="step step-primary">Complete</li>
                    </ul>
                </td> 
                <td>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                </td>
              </tr>
            </tbody> 
          </table>
        </div>

      </div>
</div>
      </>
  )
}
export default Employees;
